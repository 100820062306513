/* eslint-disable @next/next/no-img-element */
import { useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import ReactPlayer from "react-player/lazy";

import { useAppContext } from "@/context/Context";
import AppConfigs from "@/configs";

import "venobox/dist/venobox.min.css";
import HomeData from "../../data/home.json";

import bannerImg from "../../public/images/banner/video.png";
import separator from "../../public/images/separator/separator-top.svg";
import separatorLight from "../../public/images/light/separator/separator-top.svg";
import useIsMobile from "../../hooks/useMoblie";

import loaderGif from "../../public/images/logo/logo-ana.gif";
// import videoHome from "../../public/video/DillyAIAnimation.mp4";

const Home = () => {
  const { isLightTheme } = useAppContext();
  const isMobile = useIsMobile();

  useEffect(() => {
    import("venobox/dist/venobox.min.js").then((venobox) => {
      new venobox.default({
        selector: ".popup-video",
        maxWidth: window.innerWidth >= 992 ? "50%" : "100%",
      });
    });
  }, []);

  return (
    <>
      <div
        className="slider-area slider-style-1 variation-default slider-bg-image bg-banner1"
        data-black-overlay="1"
      >
        <div className="container">
          <div style={{ display: isMobile ? "none" : "block" }}>
            <img
              src={"/images/header-icons.svg"}
              style={{ width: "100%", height: 640, marginTop: -150, marginBottom: -150 }}
              alt="Banner Images"
            />
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-12 mb--60">
              <div className="inner text-center mt--60">
                {/* <h1 className="title display-one">
                  {`${HomeData.info.title} `}
                  <span className="theme-gradient">{HomeData.info.appName}</span>
                </h1> */}
                <Image
                  src={loaderGif}
                  width={400}
                  height={200}
                  alt="Loader Images"
                  // style={{ height: 200, resizeMode: "contain" }}
                />
                <p className="b1 desc-text">{HomeData.info.desc}</p>
                <div className="button-group">
                  <Link
                    className="btn-default bg-light-gradient btn-large"
                    // href={`${AppConfigs.WEB_APP_URL}/app/explore`}
                    // target="_blank"
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#newchatModal"
                  >
                    <div className="has-bg-light"></div>
                    <span>{HomeData.info.btnLaunch}</span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-10 col-xl-10 order-1 order-lg-2">
              <div className="frame-image-bottom bg-flashlight video-popup icon-center">
                <iframe
                  src="https://www.youtube.com/embed/y-kKeCvDNT8?&autoplay=1&mute=1&loop=1&controls=0&showinfo=0&rel=0&playlist=y-kKeCvDNT8"
                  // allow="autoplay"
                  height={650}
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  // allowfullscreen=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="chatenai-separator has-position-bottom">
          <Image
            className={`w-100 ${isLightTheme ? "separator-dark" : "separator-light"}`}
            src={isLightTheme ? separator : separatorLight}
            alt="separator"
          />
        </div>
      </div>
    </>
  );
};

export default Home;
