import React, { useEffect, useState, useMemo } from "react";
import Separator from "@/pages/separator";
import Image from "next/image";
import Link from "next/link";
import { useAppContext } from "@/context/Context";

import logo from "../../public/images/logo/logo.png";
import logoDark from "../../public/images/logo/logo-dark.png";

import FooterData from "../../data/footer.json";
import SingleFooter from "./FooterProps/SingleFooter";

const Footer = () => {
  const { isLightTheme } = useAppContext();

  const [email, setEmail] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const isDisabled = useMemo(() => {
    // validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return !emailRegex.test(email);
  }, [email]);

  const handleSubmitEmail = () => {
    console.log(email);
    fetch("https://api-dev.dilly-ai.com/api/user/v1/newsletter/subscribe", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        productType: "DILLY_AI_WAITING_LIST",
        email: email,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        setIsSuccess(true);
        return response.json(); // Parse the JSON response
      })
      .then((data) => {
        console.log("Response from server:", data); // Handle server response
      })
      .catch((error) => {
        console.error("Error occurred:", error); // Handle errors
      });
  };

  return (
    <>
      <footer className="rainbow-footer footer-style-default footer-style-3 position-relative mt-0">
        <Separator isLightTheme={isLightTheme} top={true} />
        <div className="footer-top">
          <div className="container">
            <div className="row justify-content-center mb--30">
              <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                <div className="rainbow-footer-widget text-center">
                  <div className="logo">
                    <Link href="/">
                      <Image
                        className={isLightTheme ? "logo-light" : "logo-dark m-auto"}
                        src={isLightTheme ? logo : logoDark}
                        width={201}
                        height={35}
                        alt="Corporate Logo"
                        style={{ objectFit: "contain" }}
                      />
                    </Link>
                  </div>
                  <p className="b1 text-center mt--20 mb--0">
                    Dilly AI - Your smart AI agents work for you
                  </p>
                </div>
              </div>
            </div>
            <div className="separator-animated animated-true mt--50 mb--50"></div>
            {FooterData &&
              FooterData.footer.map((data, index) => (
                <div className="row" key={index}>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 bg-color-blackest p-4 rounded-player">
                    <div className="rainbow-footer-widget">
                      <h4 className="title">{data.title}</h4>
                      <div className="inner">
                        <h6 className="subtitle">{data.desc}</h6>
                        {isSuccess ? (
                          <h5 className="title text-center">
                            Success! Thank you for your interest.
                          </h5>
                        ) : (
                          <div className="newsletter-form">
                            <div className="form-group">
                              <input
                                type="email"
                                placeholder="Enter Your Email Here"
                                onChange={(e) => setEmail(e.target.value)}
                              />
                              <button
                                className="btn-default bg-solid-primary mt-4"
                                type="submit"
                                style={{
                                  // width: 200,
                                  marginTop: 20,
                                  cursor: isDisabled ? "not-allowed" : "pointer",
                                }}
                                disabled={isDisabled}
                                onClick={handleSubmitEmail}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <SingleFooter data={data.products} />
                  <SingleFooter data={data.company} />
                  <SingleFooter data={data.solutions} />
                  {/* <SingleFooter data={data.solutions} /> */}
                </div>
              ))}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
