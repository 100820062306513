import React, { useEffect, useState, useMemo } from "react";
import sal from "sal.js";

import Items from "../Dashboard/items";
import Link from "next/link";

const Modal = () => {
  const [selectedValues, setSelectedValues] = useState([]);

  const handleChange = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions, (option) => option.value);
    setSelectedValues(selectedOptions);
  };

  useEffect(() => {
    sal();
  }, []);

  const [email, setEmail] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const isDisabled = useMemo(() => {
    // validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return !emailRegex.test(email);
  }, [email]);

  const handleSubmitEmail = () => {
    console.log(email);
    fetch("https://api-dev.dilly-ai.com/api/user/v1/newsletter/subscribe", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        productType: "DILLY_AI_WAITING_LIST",
        email: email,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        setIsSuccess(true);
        return response.json(); // Parse the JSON response
      })
      .then((data) => {
        console.log("Response from server:", data); // Handle server response
      })
      .catch((error) => {
        console.error("Error occurred:", error); // Handle errors
      });
  };

  return (
    <>
      {/* ==== New Chat Section Modal ==== */}
      <div id="newchatModal" className="modal rbt-modal-box copy-modal fade" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content wrapper top-flashlight light-xl">
            <div
              className="section-title text-center mb--30 sal-animate"
              data-sal="slide-up"
              data-sal-duration="400"
              data-sal-delay="150"
            >
              <h3 className="title mb--0 w-600">Sign Up Waiting List</h3>
            </div>
            <div className="genarator-section">
              {isSuccess ? (
                <h5 className="title text-center">Success! Thank you for your interest.</h5>
              ) : (
                <div className="form-group text-center">
                  <input
                    type="email"
                    placeholder="Enter Your Email Here"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <button
                    className="btn-default bg-solid-primary"
                    type="submit"
                    style={{
                      width: 200,
                      marginTop: 20,
                      cursor: isDisabled ? "not-allowed" : "pointer",
                    }}
                    disabled={isDisabled}
                    onClick={handleSubmitEmail}
                    // data-bs-dismiss="modal"
                  >
                    Submit
                  </button>
                </div>
              )}
            </div>
            <button className="close-button" data-bs-dismiss="modal">
              <i className="feather-x"></i>
            </button>
          </div>
        </div>
      </div>

      {/* ==== Like Section Modal ==== */}
      <div id="likeModal" className="modal rbt-modal-box like-modal fade" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content wrapper top-flashlight leftside light-xl">
            <h5 className="title">Provide additional feedback</h5>
            <div className="chat-form">
              <div className="border-gradient text-form">
                <textarea rows="6" placeholder="Send a message..."></textarea>
              </div>
            </div>
            <div className="bottom-btn mt--20">
              <Link className="btn-default btn-small round" href="#">
                Send Feedback
              </Link>
            </div>
            <button className="close-button" data-bs-dismiss="modal">
              <i className="feather-x"></i>
            </button>
          </div>
        </div>
      </div>

      {/* ==== DisLike Section Modal ==== */}
      <div id="dislikeModal" className="modal rbt-modal-box dislike-modal fade" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content wrapper top-flashlight leftside light-xl">
            <h5 className="title">Why do you like this response?</h5>
            <select
              className="form-select"
              multiple
              aria-label="multiple select example"
              value={selectedValues}
              onChange={handleChange}
            >
              <option value="1">Irrelevant</option>
              <option value="2">Offensive</option>
              <option value="3">Not Correct</option>
            </select>
            <div className="chat-form">
              <h6 className="title">Provide your feedback</h6>
              <div className="border-gradient text-form">
                <textarea rows="6" placeholder="Send a message..."></textarea>
              </div>
            </div>
            <div className="bottom-btn mt--20">
              <Link className="btn-default btn-small round" href="#">
                Send Feedback
              </Link>
            </div>
            <button className="close-button" data-bs-dismiss="modal">
              <i className="feather-x"></i>
            </button>
          </div>
        </div>
      </div>

      {/* ==== Share Section Modal ==== */}
      <div id="shareModal" className="modal rbt-modal-box share-modal fade" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content wrapper modal-small top-flashlight leftside light-xl">
            <h5 className="title">Share</h5>
            <ul className="social-icon social-default transparent-with-border mb--20">
              <li data-sal="slide-up" data-sal-duration="400" data-sal-delay="200">
                <Link href="https://www.facebook.com/">
                  <i className="feather-facebook"></i>
                </Link>
              </li>
              <li data-sal="slide-up" data-sal-duration="400" data-sal-delay="300">
                <Link href="https://www.twitter.com">
                  <i className="feather-twitter"></i>
                </Link>
              </li>
              <li data-sal="slide-up" data-sal-duration="400" data-sal-delay="400">
                <Link href="https://www.instagram.com/">
                  <i className="feather-instagram"></i>
                </Link>
              </li>
              <li data-sal="slide-up" data-sal-duration="400" data-sal-delay="500">
                <Link href="https://www.linkdin.com/">
                  <i className="feather-linkedin"></i>
                </Link>
              </li>
            </ul>
            <div className="chat-form">
              <div className="border-gradient text-form d-flex align-items-center">
                <input
                  type="text"
                  className="copy-link-input"
                  defaultValue="https://www.youtube.com/"
                  readOnly
                />
                <button className="btn-default bg-solid-primary" type="submit">
                  Copy
                </button>
              </div>
            </div>
            <button className="close-button" data-bs-dismiss="modal">
              <i className="feather-x"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
